import React, { useState, useEffect } from 'react';
import { Box, InputBase, Link, Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import hasFeatureAccess from './utils/featureAccess';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HubIcon from '@mui/icons-material/Hub';

const StyledLink = styled(RouterLink)({
  display: 'flex',
  marginBottom: '8px',
  color: '#FFFFFF',
  textDecoration: 'none',
  fontSize: '16px',
  fontFamily: 'Lato',
  fontWeight: '700',
  lineHeight: '24px',
  wordWrap: 'break-word',
  '&:hover': {
    textDecoration: 'underline', // Optional: Add underline on hover
  },
});

const Search = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#332F42', // Updated background color
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  marginBottom: '16px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    //padding: theme.spacing(1, 1, 1, 0),
    //paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    if (!userId) {
      navigate('/');
    }
  }, [userId, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get('q');
    if (q !== searchQuery) {
      setSearchQuery(q || '');
    }
  }, [location.search]);

  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem('loginData');
    localStorage.removeItem('user_id');
    localStorage.removeItem('features');
    localStorage.removeItem('package');
    localStorage.removeItem('adminLogin');

    // Redirect to the login page
    window.location.replace('/');
  };

  const handleSearch = async () => {
    navigate(`/home?q=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box data-layer="Nav" className="Nav" sx={{ width: 251, height: 'auto', background: 'black', flexShrink: 0 }}>
        <Box sx={{ padding: '24px 24px 0' }}>
        <Link href="/home">
          <img src="/images/AnalyticsLogo_White.png" alt="Logo" style={{ marginBottom: '16px', height: '100px' }} />
        </Link>

        <Tooltip title="Search bill number or title">
        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: '#FFF' }} />
          </SearchIconWrapper>
          
          <StyledInputBase
            placeholder="Basic Search"
            value={searchQuery}
            sx={{ color: '#BCBCBC' }}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          {searchQuery && (
            <Box
              sx={{ cursor: 'pointer', marginLeft: '8px', color: '#FFF' }}
              onClick={() => {
                navigate(`/home`);
              }}
            >
              ✕
            </Box>
          )}
        </Search>
        </Tooltip>

        <StyledLink to="/home?tracked=1" sx={{ color: location.pathname === '/home' ? '#9C00FF' : '#FFFFFF' }}>
          Bill Tracker
        </StyledLink>

        {hasFeatureAccess('chatbot') && (
          <StyledLink to="/chat" sx={{ color: location.pathname === '/chat' ? '#9C00FF' : '#FFFFFF' }}>
            Chatbot
          </StyledLink>
        )}

        {userId == 290 && (
          <StyledLink to="/custom-chat" sx={{ color: location.pathname === '/custom-chat' || location.pathname.includes('message') || location.pathname.includes('files') ? '#9C00FF' : '#FFFFFF' }}>
            Custom Chatbots
          </StyledLink>
        )}

        {hasFeatureAccess('tags') && (
          <StyledLink to="/tag" sx={{ color: location.pathname === '/tag' ? '#9C00FF' : '#FFFFFF' }}>
            Tags
          </StyledLink>
        )}

        {hasFeatureAccess('notifications') && (
          <StyledLink to="/notification" sx={{ color: location.pathname === '/notification' ? '#9C00FF' : '#FFFFFF' }}>
            Notifications
          </StyledLink>
        )}

        {hasFeatureAccess('scorecard') && (
          <StyledLink to="/scorecard" sx={{ color: location.pathname === '/scorecard' ? '#9C00FF' : '#FFFFFF' }}>
            Scorecard
          </StyledLink>
        )}

        {hasFeatureAccess('network_analytics') && (
          <StyledLink to="/network-analytics" sx={{ color: location.pathname === '/network-analytics' ? '#9C00FF' : '#FFFFFF' }}>
            Network Analytics
          </StyledLink>
        )}

        {hasFeatureAccess('upcoming') && (
          <StyledLink to="/upcoming" sx={{ color: location.pathname === '/upcoming' ? '#9C00FF' : '#FFFFFF' }}>
            Upcoming Votes
          </StyledLink>
        )}

        <StyledLink class="profile" to="/profile" sx={{ color: location.pathname === '/profile' ? '#9C00FF' : '#FFFFFF' }}>
          Profile
        </StyledLink>
        
        <StyledLink onClick={handleLogout} sx={{ color: '#FFFFFF', position: 'absolute', bottom: '76px' }}>
          Logout
        </StyledLink>
        </Box>
      </Box>
      <Box data-layer="right" className="Right" sx={{ width: '100vW', overflowY: 'scroll', height: '100vh', background: 'white', paddingLeft: '24px', paddingTop: '24px' }}>
        {children}
      </Box>
    </Box>
  );
}

export default Layout;