import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Layout from './Layout';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import truncateText from './utils/truncateText';

function HomePage() {
  const location = useLocation();
  const [bills, setBills] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');

  const showTruncatedText = (text, limit) => {
    const truncated = truncateText(text, limit);
    return text.length > truncated.length;
  };

  const handleSearch = async (page) => {
    setLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const query = params.get('q') || '';
      const tracked = params.get('tracked');
      setSearchQuery(query || '');

      let apiUrl = `${process.env.REACT_APP_API_URL}/bills/search/${userId}/?q=${query}&page=${page}`;

      if (tracked) {
        apiUrl += `&tracked=${tracked}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setBills(data.bills || []);
      setCurrentPage(data.page);
      setTotalPages(data.pages);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [location.search, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleBookmarkClick = async (billId) => {
    try {
      const billIndex = bills.findIndex(bill => bill.id === billId);
      if (billIndex === -1) {
        throw new Error('Bill not found');
      }

      const isBookmarked = bills[billIndex].tracking === 1;
      const action = isBookmarked ? 'delete' : 'add';

      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({ bill_id: billId, user_id: userId, action: action }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(`Bill ${action}ed successfully:`, data);

      // Update the bill's tracking status in the state
      const updatedBills = [...bills];
      updatedBills[billIndex] = {
        ...updatedBills[billIndex],
        tracking: isBookmarked ? 0 : 1,
      };
      setBills(updatedBills);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout searchQuery={searchQuery}>
      <Box
        sx={{
          overflowY: 'auto',
          padding: 2,
          width: '100%',
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {bills.length === 0 ? (
              <Typography>No results found</Typography>
            ) : (
              bills.map(bill => (
                <React.Fragment key={bill.id}>
                  <Box
                    sx={{
                      backgroundColor: '#F4F4F4',
                      borderRadius: 2,
                      padding: 2,
                      maxWidth: '90%',
                      marginBottom: 2,
                      fontFamily: 'Lato',
                    }}
                  >
                    <Link
                      to={`/bill/${bill.id}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <Typography>
                        {bill.tracking === 1 ? (
                          <BookmarkIcon
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              handleBookmarkClick(bill.id);
                            }}
                            style={{ verticalAlign: 'middle', marginRight: 8, cursor: 'pointer' }}
                          />
                        ) : (
                          <BookmarkBorderIcon
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              
                              handleBookmarkClick(bill.id);
                            }}
                            style={{ verticalAlign: 'middle', marginRight: 8, cursor: 'pointer' }}
                          />
                        )}

                        {`${bill.year_introduced} | ${bill.state} ${bill.bill_number} ${bill.title ? ` - ${bill.title}` : ''}`}

                        {bill.policy_objective && bill.policy_objective.length > 0 ? (
                          <Typography sx={{ marginTop: 1 }}>
                            {showTruncatedText(bill.policy_objective, 45) ? (
                              <>
                                {bill.showPolicyObjective ? (
                                  <>
                                    {bill.policy_objective}
                                    <Button
                                      variant="text"
                                      size="small"
                                      startIcon={<ExpandLessIcon />}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const updatedBills = bills.map(b =>
                                          b.id === bill.id ? { ...b, showPolicyObjective: false } : b
                                        );
                                        setBills(updatedBills);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {truncateText(bill.policy_objective, 45)}
                                    <Button
                                      variant="text"
                                      size="small"
                                      startIcon={<ExpandMoreIcon />}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const updatedBills = bills.map(b =>
                                          b.id === bill.id ? { ...b, showPolicyObjective: true } : b
                                        );
                                        setBills(updatedBills);
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              bill.policy_objective
                            )}
                          </Typography>
                        ) : bill.description && bill.description.length > 0 ? (
                          <Typography sx={{ marginTop: 1 }}>
                            {showTruncatedText(bill.description, 45) ? (
                              <>
                                {bill.showFullDescription ? (
                                  <>
                                    {bill.description}
                                    <Button
                                      variant="text"
                                      size="small"
                                      startIcon={<ExpandLessIcon />}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const updatedBills = bills.map(b =>
                                          b.id === bill.id ? { ...b, showFullDescription: false } : b
                                        );
                                        setBills(updatedBills);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {truncateText(bill.description, 45)}
                                    <Button
                                      variant="text"
                                      size="small"
                                      startIcon={<ExpandMoreIcon />}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const updatedBills = bills.map(b =>
                                          b.id === bill.id ? { ...b, showFullDescription: true } : b
                                        );
                                        setBills(updatedBills);
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              bill.description
                            )}
                          </Typography>
                        ) : null}

                        {bill.status && (
                          <Typography sx={{ marginTop: 1 }}>
                            <b>Status:</b> {bill.status}
                          </Typography>
                        )}

                        {bill.controversy_level && (
                          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <Typography sx={{ marginRight: 1 }}>
                              <b>Controversy:</b>
                            </Typography>
                            {Array.from({ length: 5 }).map((_, index) => (
                              <img
                                key={index}
                                src={index < Math.floor(bill.controversy_level / 2) ? '/images/fire-on.svg' : '/images/fire-off.svg'}
                                alt="Fire"
                                style={{ width: 20, height: 20, marginRight: 4 }}
                              />
                            ))}
                          </Box>
                        )}

                        {bill.sponsors && bill.sponsors.length > 0 && (
                          <Typography sx={{ marginTop: 1 }}>
                            <b>Sponsor(s):</b>
                            {bill.showAllSponsors
                              ? bill.sponsors.map(sponsor => sponsor.name).join(', ')
                              : bill.sponsors.slice(0, 2).map(sponsor => sponsor.name).join(', ')}
                            {bill.sponsors.length > 2 && (
                              <>
                                {bill.showAllSponsors ? (
                                  <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<ExpandLessIcon />}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const updatedBills = bills.map(b =>
                                        b.id === bill.id ? { ...b, showAllSponsors: false } : b
                                      );
                                      setBills(updatedBills);
                                    }}
                                  />
                                ) : (
                                  <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<ExpandMoreIcon />}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const updatedBills = bills.map(b =>
                                        b.id === bill.id ? { ...b, showAllSponsors: true } : b
                                      );
                                      setBills(updatedBills);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Typography>
                        )}

                        {bill.category && bill.category.length > 0 && (
                          <Typography sx={{ marginTop: 1 }}>
                            <b>Tag(s):</b> {bill.category}
                          </Typography>
                        )}
                      </Typography>
                    </Link>
                  </Box>
                  <Box sx={{ height: 16 }} /> {/* Spacer element */}
                </React.Fragment>
              ))
            )}

            <Box display="flex" justifyContent="left" alignItems="left" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                sx={{ marginRight: 2 }}
              >
                Previous
              </Button>
              <Typography variant="body1">
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                sx={{ marginLeft: 2 }}
              >
                Next
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Layout>
  );
}

export default HomePage;