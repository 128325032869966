import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';

const EvidenceDialog = ({ open, onClose, evidence, dialogLoading, currentCandidate }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ color: 'black', fontSize: 40, fontFamily: 'Lato', fontWeight: '700', wordWrap: 'break-word' }}>
                {currentCandidate}
            </DialogTitle>
            <DialogContent>
                {dialogLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    evidence.map((item) => (
                        <Box key={item.id} sx={{ marginBottom: 2, borderRadius: '8px', padding: 2, background: '#F4F4F4' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="body1">
                                    <strong>
                                        {item.source_type === 'bill' ? (
                                            <>
                                                <Box sx={{ color: 'black', fontSize: 24, fontFamily: 'Lato', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                                                    <a href={item.url} target="_blank"
                                                       style={{textDecoration: 'none', color: 'inherit'}}>
                                                        <img src="/images/bill.svg" alt="Bill Icon" style={{
                                                            marginLeft: '8px',
                                                            width: '24px',
                                                            height: '24px'
                                                        }}/>
                                                        &nbsp;
                                                        <span style={{textDecoration: 'underline', color: 'inherit'}}>
                                                            {`${item.year_introduced} | ${item.state} ${item.bill_number}${item.title ? ` - ${item.title}` : ''}`}
                                                        </span>
                                                    </a>
                                                    <Box sx={{marginLeft: 'auto'}}>
                                                        <a href={item.url} target="_blank"
                                                           style={{textDecoration: 'none', color: 'black'}}>
                                                            {item.reason === 'Voted Yea' ? '👍' : item.reason === 'Voted Nay' ? '👎' : ''}
                                                        </a>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ color: 'black', fontSize: 16, fontFamily: 'Lato', fontWeight: '400', wordWrap: 'break-word' }}>
                                                    {item.notification_reason}
                                                    <br /><br />
                                                    {item.reason}
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Box sx={{ color: 'black', fontSize: 24, fontFamily: 'Lato', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                                                    <img src="/images/bill.svg" alt="Bill Icon" style={{ marginLeft: '8px', width: '24px', height: '24px' }} />
                                                    &nbsp;{item.source_type.charAt(0).toUpperCase() + item.source_type.slice(1)} Statement
                                                    <Box sx={{ marginLeft: 'auto' }}>
                                                        <a href={item.url} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>Source</a>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, background: 'white', borderTopLeftRadius: 8, borderTopRightRadius: 8, borderLeft: '4px #9C00FF solid' }}>
                                                    <Typography variant="body1" sx={{ fontWeight: '700' }}>
                                                        {item.original_text}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                    </strong>
                                </Typography>
                            </Box>
                        </Box>
                    ))
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EvidenceDialog;
