import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CustomSelect from './CustomSelect';

const FilterDialog = ({ open, onClose, chambers, selectedChambers, handleSelectChangeChamber, parties, selectedParties, handleSelectChangeParty, attributes, selectedAttributes, handleSelectChangeAttribute, handleFilterSave, isPublic = false }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Filter Candidates</DialogTitle>
            <DialogContent>
                <CustomSelect
                    label="Chamber"
                    options={chambers.map((chamber) => ({ name: chamber }))}
                    value={selectedChambers}
                    onChange={handleSelectChangeChamber}
                    isMulti={true}
                />
                <CustomSelect
                    label="Parties"
                    options={parties.map((party) => ({ name: party }))}
                    value={selectedParties}
                    onChange={handleSelectChangeParty}
                    isMulti={true}
                />
                {!isPublic && (
                    <CustomSelect
                        label="Angle"
                        options={attributes}
                        value={selectedAttributes}
                        onChange={handleSelectChangeAttribute}
                        isMulti={true}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleFilterSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
