import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import CandidateDetails from './components/CandidateDetails';
import {Alert, Box, CircularProgress, Typography} from "@mui/material";

const CandidatePage = ({ isPublic = false, candidateId }) => {
    const { id } = useParams();

    return isPublic ? (
        <CandidateDetails candidateId={candidateId} />
    ) : (
        <Layout>
            <CandidateDetails candidateId={id} />
        </Layout>
    );
};

export default CandidatePage;
