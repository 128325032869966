import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper,
  Box, Tabs, Tab, Typography, CircularProgress, Button, Tooltip, Pagination, IconButton, Select, MenuItem,
  FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import EvidenceDialog from './components/EvidenceDialog';
import { getEvidence, fetchTags } from './api/apiService';
import { buildQueryString } from './utils/utils';
import { fetchStatements } from './api/apiService';
import AttributesTableCell from './components/AttributesCandidate';

function VotesTab({ id }) {
  const navigate = useNavigate();
  const [votesData, setVotesData] = useState({});
  const [isVotesDataFetched, setIsVotesDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [bill, setBill] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [voteCounts, setVoteCounts] = useState({});

  const userId = localStorage.getItem('user_id');
  const [openRow, setOpenRow] = useState(null);
  const [tags, setTags] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState('');
  const [isEvidenceDialogOpen, setIsEvidenceDialogOpen] = useState(false);
  const [evidence, setEvidence] = useState([]);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const [partyFilter, setPartyFilter] = useState('');
  const [chamberFilter, setChamberFilter] = useState('');
  const [angleTypes, setAngleTypes] = useState([]);
  const [angleFilter, setAngleFilter] = useState([]);
  const [myViewFilter, setMyViewFilter] = useState([]);

  const [pageSize, setPageSize] = useState(10); // Number of items displayed per page
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('full_name');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [sponsorFilter, setSponsorFilter] = useState('');
  const [sponsorOptions, setSponsorOptions] = useState([]);

  const [statements, setStatements] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const [statementFilter, setStatementFilter] = useState('');

  const queryParams = {
    page: currentPage,
    page_size: pageSize,
    sort_by: orderBy,
    sort_order: order,
    party: partyFilter,
    chamber: chamberFilter,
    angle: angleFilter,
    my_view: myViewFilter,
    sponsor: sponsorFilter,
    statement: statementFilter,
  };

  const icons = {
    'Strongly Disagree': { text: 'Firm No', emoji: '🤯', color: '#F99E17' },
    'Disagree': { text: 'Lean No', emoji: '😕', color: '#FFE500' },
    'Neutral': { text: 'Open', emoji: '😐', color: '#949494' },
    'Agree': { text: 'Lean Yes', emoji: '🙂', color: '#00E709' },
    'Strongly Agree': { text: 'Firm Yes', emoji: '🥳', color: '#00A006' }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/sponsor-types/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
        .then((res) => res.json())
        .then((data) => {
          const options = data.map((item) => item.value);
          setSponsorOptions(options);
        })
        .catch((error) => {
          console.error('Failed to fetch sponsor types:', error);
        });
  }, []);

  useEffect(() => {
    if (!userId) {
        setTags([]);
    } else {
        fetchTags(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, setTags,{
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        });
    }
  }, [userId]);

  useEffect(() => {
    getBill(id);
  }, [id]);

  useEffect(() => {
    const getStatements = async () => {
      try {
        const data = await fetchStatements(userId);
        setStatements(data);
      } catch (error) {
        setErrorMessage(error.message || 'Failed to load statements.');
        setTimeout(() => setErrorMessage(null), 3000);
      }
    };

    getStatements();
  }, [userId]);

  const fetchCandidateEvidence = (attribution_id, candidate_name) => {
      getEvidence(attribution_id, candidate_name, setDialogLoading, setCurrentCandidate, setIsEvidenceDialogOpen, setEvidence);
  };

  const getBill = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/bills/info/${id}/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setBill(data.bill);

        if (data.bill.status === 'Introduced') {
          getWhipCount(id, queryParams);
        } else {
          getVotes(id, orderBy, order);
        }
      })
      .catch(error => {
        console.error('Error fetching bill text:', error);
      });
  };

  const getVotes = (id) => {
    if (!isVotesDataFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-votes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          const transformedData = transformVoteData(data);
          setVotesData(transformedData);
          setIsVotesDataFetched(true);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching votes data:', error);
          setIsLoading(false);
        });
    }
  };

  const transformVoteData = (data) => {
    return data.map(vote => ({
      ...vote,
      total_score: vote.agreement !== undefined ? vote.agreement : vote.total_score,
      attributes: vote.angle !== undefined ? vote.angle : vote.attributes,
      full_name: vote.candidate_name !== undefined ? vote.candidate_name : vote.full_name,
      party_name: vote.party !== undefined ? vote.party : vote.party_name,
      position_name: vote.chamber !== undefined ? vote.chamber : vote.position_name,
    }));
  };

  const getWhipCount = (id, params) => {
    setIsLoading(true);

    const queryString = buildQueryString(params);
    fetch(`${process.env.REACT_APP_API_URL}/whip-vote/by-bill/${id}/${userId}?${queryString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        const newData = {};
        newData['Whip Count'] = [
          {
            votes: data.results.candidates,
            name: 'Whip Count'
          }
        ];
        setTotalCount(data.count);
        setVoteCounts(data.results.vote_counts);
        setAngleTypes(data.results.attribution_types);
        setVotesData(newData);
        setTotalPages(Math.ceil(data.count / params.page_size)); // Assuming 10 items per page
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching votes data:', error);
        setIsLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    // Update both order and orderBy states
    setOrder(newOrder);
    setOrderBy(property);

    // Use the new values directly for further logic
    const updatedQueryParams = { ...queryParams, sort_by: property, sort_order: newOrder };

    if (bill.status === 'Introduced') {
        getWhipCount(id, updatedQueryParams);
    } else {
        getVotes(id);
    }
  };

  const handlePageSizeChange = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setCurrentPage(1);

    const updatedParams = {
      ...queryParams,
      page: 1,
      page_size: value,
    };

    getWhipCount(id, updatedParams);
  };

  const handleFilterClick = () => {
    setIsFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setIsFilterDialogOpen(false);
  };

  const handlePartyFilterChange = (event) => {
    setPartyFilter(event.target.value);
  };

  const handleChamberFilterChange = (event) => {
    setChamberFilter(event.target.value);
  };

  const handleStatementChange = (event) => {
    setStatementFilter(event.target.value);
  };

  const clearStatementFilter = () => {
    setStatementFilter('');
  };

  const keys = Object.keys(votesData);

  const handleRowClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const handleClick = async (candidate_id, bill_id, key) => {
    const updatedVotes = votesData['Whip Count'][0]['votes'].map(vote =>
      vote.candidate_id === candidate_id ? { ...vote, whip_vote: key } : vote
    );
    votesData['Whip Count'][0]['votes'] = updatedVotes;
    setVotesData({ ...votesData });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/whip-vote/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
      body: JSON.stringify({
        bill: bill_id,
        candidate: candidate_id,
        user: userId,
        vote_type: key,
      }),
    });

    if (response.ok) {
      const data = await response.json();

      getWhipCount(id, queryParams);
    } else {
      console.error('Error submitting vote:', response.statusText);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getWhipCount(id, buildQueryParams({ page }));
  };

  const handleSearch = () => {
    setCurrentPage(1);

    getWhipCount(id, buildQueryParams({ page: 1 }));
  };

  const buildQueryParams = (overrides = {}) => {
    return {
      ...queryParams,
      ...overrides,
    };
  };

  const isIntroduced = bill.status === 'Introduced';

  const columnsConfig = useMemo(() => {
    const baseColumns = [
      { key: 'sponsor', label: 'Sponsor' },
      { key: 'full_name', label: 'Name' },
      { key: 'party_name', label: 'Party' },
      { key: 'position_name', label: 'Chamber' },
      { key: 'district', label: 'District' },
    ];

    if (isIntroduced) {
      baseColumns.push(
          { key: 'attributes', label: 'Angle(s)' },
          { key: 'total_score', label: 'Agreement' },
          { key: 'activity_level', label: 'Activity Level' },
          { key: 'my_view', label: 'My View' }
      );
    } else {
      baseColumns.push({ key: 'vote', label: 'Vote' });
    }

    return baseColumns;
  }, [isIntroduced]);

  const renderVoteCells = (vote) => (
    <>
      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.sponsor}</TableCell>
      <TableCell sx={{ border: '1px solid #E0E0E0' }}>
        <Box display="flex" alignItems="center">
          <a
            href={`/candidate/${vote.candidate_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
          >
            {vote.photo_url ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  marginRight: '8px',
                  flexShrink: 0,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={vote.photo_url}
                  alt={vote.candidate_name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: 'gray',
                  marginRight: '8px',
                  flexShrink: 0,
                }}
              />
            )}
            {vote.candidate_name}
          </a>
        </Box>
      </TableCell>
      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.party}</TableCell>
      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.position_name}</TableCell>
      <TableCell sx={{ border: '1px solid #E0E0E0' }}>
        {vote.position_name === "U.S. Senate"
          ? vote.state
          : vote.position_name === "U.S. House"
          ? vote.district && vote.district !== 0
            ? `${vote.state}-${vote.district}`
            : vote.state
          : vote.district}
      </TableCell>

      {isIntroduced ? (
        <>
          <AttributesTableCell vote={vote} isIntroduced={isIntroduced} />
          <TableCell sx={{ border: '1px solid #E0E0E0' }}>
            {vote.total_score}
            {vote.total_score !== 'N/A' && (
              <IconButton onClick={() => handleRowClick(vote.id)}>
                {openRow === vote.id ? <MinusIcon fontSize="small" /> : <AddIcon fontSize="small" />}
              </IconButton>
            )}
          </TableCell>
          <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.activity_level}</TableCell>
          <TableCell>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {Object.entries(icons).map(([key, { text, color }], index) => (
                <Tooltip key={index} title={key} arrow>
                  <Button
                    onClick={() => handleClick(vote.candidate_id, bill.id, key)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 60,
                      height: 60,
                      fontSize: 10,
                      border: '1px solid #E0E0E0',
                      borderRadius: '50%',
                      backgroundColor: vote['whip_vote'] === key ? color : '#f0f0f0',
                      '&:hover': {
                        backgroundColor: color,
                        color: 'white'
                      },

                      color: vote['whip_vote'] === key ? 'white' : 'black'
                    }}
                  >
                    {text}
                  </Button>
                </Tooltip>
              ))}
                </Box>
              </TableCell>
            </>
        ) : (
            <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.vote}</TableCell>
        )}
      </>
  );

  return (
      <Box sx={{ width: '100%' }}>
        {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <CircularProgress />
            </Box>
        ) : (
            <>
              {bill && (
                  <>
                    <Tabs value={value} onChange={handleChange} aria-label="votes tabs">
                      {keys.map((key, index) => (
                          <Tab label={key} key={index} />
                      ))}
                    </Tabs>
                    {isIntroduced && (
                        <Button variant="outlined"
                                sx={{ marginTop: 2, marginLeft: 3}}
                                color="primary"
                                onClick={handleFilterClick}>
                          Filter
                        </Button>
                    )}
                    <Dialog
                        open={isFilterDialogOpen}
                        onClose={handleFilterDialogClose}
                        maxWidth="xs"
                        fullWidth
                    >
                      <DialogTitle>Filter(s)</DialogTitle>
                      <DialogContent>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="party-filter-label">Party</InputLabel>
                            <Select
                                labelId="party-filter-label"
                                id="party-filter"
                                value={partyFilter}
                                onChange={handlePartyFilterChange}
                                label="Party"
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              <MenuItem value="Democrat">Democrat</MenuItem>
                              <MenuItem value="Republican">Republican</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="chamber-filter-label">Chamber</InputLabel>
                            <Select
                                labelId="chamber-filter-label"
                                id="chamber-filter"
                                value={chamberFilter}
                                onChange={handleChamberFilterChange}
                                label="Chamber"
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              <MenuItem value="House">House</MenuItem>
                              <MenuItem value="Senate">Senate</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="angle-type-filter-label">Angle Type</InputLabel>
                            <Select
                                labelId="angle-type-filter-label"
                                id="angle-type-filter"
                                multiple
                                value={angleFilter}
                                onChange={(event) => setAngleFilter(event.target.value)}
                                label="Angle Type"
                                renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {angleTypes.map((type) => (
                                  <MenuItem key={type} value={type}>
                                    <Checkbox checked={angleFilter.indexOf(type) > -1} />
                                    {type}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="my-view-filter-label">My View</InputLabel>
                            <Select
                                labelId="my-view-filter-label"
                                id="my-view-filter"
                                multiple
                                value={myViewFilter}
                                onChange={(event) => setMyViewFilter(event.target.value)}
                                label="My View"
                                renderValue={(selected) => selected.map((value) => icons[value]?.text + " " + icons[value]?.emoji || value).join(', ')}
                            >
                              {Object.keys(icons).map((key) => (
                                  <MenuItem key={key} value={key}>
                                    <Checkbox checked={myViewFilter.indexOf(key) > -1} />
                                    {icons[key].emoji} {icons[key].text}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="sponsor-filter-label">Sponsor Type</InputLabel>
                            <Select
                                labelId="sponsor-filter-label"
                                id="sponsor-filter"
                                value={sponsorFilter}
                                onChange={(event) => setSponsorFilter(event.target.value)}
                                label="Sponsor Type"
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {sponsorOptions.map((type) => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="statement-filter-label">Statement</InputLabel>
                            <Select
                                labelId="statement-filter-label"
                                id="statement-filter"
                                value={statementFilter}
                                onChange={handleStatementChange}
                                label="Statement"
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {statements.map((statement) => (
                                  <MenuItem key={statement.id} value={statement.id}>
                                    {statement.statement}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => { handleSearch(); handleFilterDialogClose(); }}>Search</Button>
                        <Button onClick={handleFilterDialogClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    {keys.length === 0 ? (
                        <Typography>{isIntroduced ? 'Hi' : 'No Votes'}</Typography>
                    ) : (
                        keys.map((key, index) => (
                            <TabPanel value={value} index={index} key={index}>
                              {votesData[key].length === 0 ? (
                                  <Typography>No Votes</Typography>
                              ) : (
                                  votesData[key].map((rollCall, rollCallIndex) => (
                                      <React.Fragment key={rollCallIndex}>

                                        <h3>Roll Call: {rollCall.name}</h3>
                                        {isIntroduced && (
                                            <Typography>
                                              <b>Tally</b> - {(voteCounts['Strongly Agree'] || 0) + (voteCounts['Agree'] || 0)} / {totalCount} votes
                                            </Typography>
                                        )}

                                        <EvidenceDialog open={isEvidenceDialogOpen} onClose={() => setIsEvidenceDialogOpen(false)} evidence={evidence} dialogLoading={dialogLoading} currentCandidate={currentCandidate} />

                                        <TableContainer component={Paper}>
                                          <Table sx={{ minWidth: 650, border: '1px solid #E0E0E0', tableLayout: 'auto' }} aria-label="votes table">
                                            <TableHead>
                                              <TableRow sx={{ backgroundColor: '#E0E0E0' }}>
                                                {columnsConfig.map((col) => (
                                                    <TableCell key={col.key} sx={{ border: '1px solid #E0E0E0' }}>
                                                      <TableSortLabel active={orderBy === col.key}
                                                                      direction={orderBy === col.key ? order : 'asc'}
                                                                      onClick={() => handleRequestSort(col.key)}>
                                                        {col.label}
                                                      </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {rollCall.votes.map((vote, voteIndex) => (
                                                  <React.Fragment key={voteIndex}>
                                                    <TableRow>{renderVoteCells(vote)}</TableRow>
                                                    {openRow === vote.id && (
                                                        <TableRow>
                                                          <TableCell colSpan={columnsConfig.length}>
                                                            <Table>
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>Topic</TableCell>
                                                                  <TableCell>Statement</TableCell>
                                                                  <TableCell>Score</TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                {tags.map((tag) =>
                                                                    tag.statements
                                                                        .filter((statement) =>
                                                                            vote.attributions.some((attr) => attr.user_tag_statement_id === statement.id)
                                                                        )
                                                                        .map((statement) => (
                                                                            <TableRow key={statement.id}>
                                                                              <TableCell>{tag.tag}</TableCell>
                                                                              <TableCell>{statement.statement}</TableCell>
                                                                              <TableCell>
                                                                                {vote.attributions
                                                                                    .filter((attr) => attr.user_tag_statement_id === statement.id)
                                                                                    .map((attr) => (
                                                                                        <a
                                                                                            href="#"
                                                                                            key={attr.id}
                                                                                            onClick={() => fetchCandidateEvidence(attr.id, vote.candidate_name)}
                                                                                        >
                                                                                          {attr.score}
                                                                                        </a>
                                                                                    ))}
                                                                              </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </TableCell>
                                                        </TableRow>
                                                    )}
                                                  </React.Fragment>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </React.Fragment>
                                  ))
                              )}
                            </TabPanel>
                        ))
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                      <FormControl size="small" variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel id="page-size-label">Rows per page</InputLabel>
                        <Select
                            labelId="page-size-label"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            label="Rows per page"
                        >
                          {[10, 20, 50, 100, 'All'].map((size) => (
                              <MenuItem key={size} value={size === 'All' ? totalCount : size}>
                                {size}
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                      />
                    </Box>
                  </>
              )}
            </>
        )}
      </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default VotesTab;