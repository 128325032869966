export const tableHeaderCellStyle = {
    color: '#505050', 
    fontSize: 13, 
    fontFamily: 'Lato', 
    fontWeight: '700', 
    wordWrap: 'break-word'
  };

export const tableCellStyle = {
    color: 'black',
    fontSize: 12,
    fontFamily: 'Lato',
    fontWeight: '400',
    wordWrap: 'break-word',
  };

export const normalTextStyle = {
  color: 'black', 
  fontSize: 12, 
  fontFamily: 'Lato', 
  fontWeight: '500', 
  wordWrap: 'break-word'
};