import React, { useState } from 'react';
import Layout from './Layout';
import { Tabs, Tab, Box } from '@mui/material';

function NetworkAnalyticsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const userId = localStorage.getItem('user_id');

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ width: '100%' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="network analytics tabs">
          <Tab label="Vote Scenarios" />
          <Tab label="Sponsorship" />
          <Tab label="District Maps" />
        </Tabs>
        {tabIndex === 0 && (
          <Box sx={{ padding: 2 }}>
            <iframe
              src={`https://joe-weston.shinyapps.io/UA_Scenario_Planner/?user_id=${userId}`}
              style={{ width: '90%', height: '100vh', border: 'none' }}
              title="Vote Scenarios"
            />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box sx={{ padding: 2 }}>
            <iframe
              src={`https://joe-weston.shinyapps.io/Network_Scorecard/?user_id=${userId}`}
              style={{ width: '90%', height: '100vh', border: 'none' }}
              title="Network Scorecard"
            />
          </Box>
        )}
        {tabIndex === 2 && (
          <Box sx={{ padding: 2 }}>
            <iframe
              src={`https://joe-weston.shinyapps.io/Scorecard_Map/?user_id=${userId}`}
              style={{ width: '90%', height: '100vh', border: 'none' }}
              title="District Maps"
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default NetworkAnalyticsPage;