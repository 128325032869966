import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper,
  CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel,
  Select, MenuItem, IconButton, TextField,
} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { tableHeaderCellStyle, tableCellStyle } from './Styles'; 
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import hasFeatureAccess from './utils/featureAccess';
import { fetchTags } from "./api/apiService";
import NestedTable from './components/nestedTable';
import { buildQueryString } from './utils/utils.js';

function NotificationPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const initialData = useRef([]);
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [savedTag, setSavedTag] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const [urgencies, setUrgencies] = useState(['High', 'Medium', 'Low', 'Not Related']);
  const [savedUrgency, setSavedUrgency] = useState('High');
  const [selectedUrgency, setSelectedUrgency] = useState('High');

  const [relations, setRelations] = useState(['Direct','Contextual','Indirect','Not Applicable','None']);
  const [savedRelation, setSavedRelation] = useState('Direct');
  const [selectedRelation, setSelectedRelation] = useState('Direct');

  const [stances, setStances] = useState(['for', 'none', 'against']);
  const [savedStance, setSavedStance] = useState('');
  const [selectedStance, setSelectedStance] = useState('');

  const [stanceStrengths, setStanceStrengths] = useState(['completely aligned', 'mostly aligned', 'none', 'somewhat aligned', 'not aligned', 'weakly aligned']);
  const [savedStanceStrength, setSavedStanceStrength] = useState('completely aligned');
  const [selectedStanceStrength, setSelectedStanceStrength] = useState('completely aligned');

  const [savedYearIntroduced, setSavedYearIntroduced] = useState(null);
  const [selectedYearIntroduced, setSelectedYearIntroduced] = useState(null);

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number in pagination
  const [pageSize, setPageSize] = useState(10); // Number of items displayed per page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages available
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('-created_at');
  const [cachedBillDetails, setCachedBillDetails] = useState({});

  const queryParams = {
    user_id: userId,
    page: currentPage,
    page_size: pageSize,
    ordering: orderBy,
    user_tag__tag: savedTag || '',
    urgency: savedUrgency || '',
    relation: savedRelation || '',
    stance: savedStance || '',
    stance_strength: savedStanceStrength || '',
    year_introduced: savedYearIntroduced ? savedYearIntroduced.format('YYYY') : '',
  };

  const [expandedBills, setExpandedBills] = React.useState([]);

  const toggleDetails = async (item) => {
    const billId = item.bill.id;
    const url = `${process.env.REACT_APP_API_URL}/user-tag-bill/get-bill-details/?bill_id=${billId}&user_id=${userId}`;
    if (expandedBills.includes(billId)) {
      setExpandedBills((prev) => prev.filter((id) => id !== billId));
    } else {
      if (!cachedBillDetails[billId]) {
        try {
          const response = await fetch(url);
          const data = await response.json();

          setCachedBillDetails((prev) => ({
            ...prev,
            [billId]: data,
          }));
        } catch (error) {
          console.error('Error fetching bill details:', error);
        }
      }
      setExpandedBills((prev) => [...prev, billId]);
    }
  };


  useEffect(() => {
    // Fetch all tags on component mount
    const loadTags = async () => {
      try {
        fetchTags(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, setTags, {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        });
      } catch (error) {
        console.error('Error loading tags:', error);
      }
    };

    loadTags();
  }, [userId]);

  useEffect(() => {
    if (!hasFeatureAccess('notifications')) {
      navigate('/access-denied');
    }
  }, [navigate]);

  useEffect(() => {
    fetchData(queryParams);
  }, [userId, currentPage, orderBy, order,
    savedYearIntroduced, savedTag, savedUrgency, savedRelation, savedStance, savedStanceStrength, pageSize]);

  const fetchData = async (params) => {
    try {
      setLoading(true);

      const queryString = buildQueryString(params);
      const url = `${process.env.REACT_APP_API_URL}/user-tag-bill?${queryString}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const ret = await response.json();

      const user_tag_bills = ret?.user_tag_bills?.results || [];
      const user_bills = ret?.user_bills || [];
      const totalPages = Math.ceil((ret?.user_tag_bills?.count || 0) / pageSize);

      initialData.current = user_tag_bills;
      setData(user_tag_bills);
      setTracking(user_bills);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  const exportCsv = async () => {
    try {
      setLoading(true);
      var queryString = buildQueryString(queryParams);
      queryString += '&export=1';
      const url = `${process.env.REACT_APP_API_URL}/user-tag-bill?${queryString}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = 'export.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error exporting CSV:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterOpen = () => {
    setSelectedYearIntroduced(savedYearIntroduced);
    setSelectedTag(savedTag);
    setIsFilterDialogOpen(true);
  };

  const handleFilterClose = () => {
    setSelectedYearIntroduced(savedYearIntroduced);
    setSelectedTag(savedTag);
    setIsFilterDialogOpen(false);
  };

  const handleSelectChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleUrgencyChange = (e) => {
    setSelectedUrgency(e.target.value);
  };

  const handleRelationChange = (e) => {
    setSelectedRelation(e.target.value);
  };

  const handleStanceChange = (e) => {
    setSelectedStance(e.target.value);
  };

  const handleStanceStrengthChange = (e) => {
    setSelectedStanceStrength(e.target.value);
  };

  const handleFilterSave = () => {
    setSavedYearIntroduced(selectedYearIntroduced);
    setSavedTag(selectedTag);
    setSavedUrgency(selectedUrgency);
    setSavedRelation(selectedRelation);
    setSavedStance(selectedStance);
    setSavedStanceStrength(selectedStanceStrength);
    setCurrentPage(1);
    setIsFilterDialogOpen(false);
  };

  const transformOrderBy = (property, order) => {
    return order === 'desc' ? `-${property}` : property;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    const transformedOrderBy = transformOrderBy(property, newOrder);

    setOrder(newOrder);
    setOrderBy(transformedOrderBy);
    setCurrentPage(1);
  };

  const stripMinus = (value) => value.startsWith('-') ? value.slice(1) : value;

  const handleBookmarkClick = async (billId, action) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({ bill_id: billId, user_id: userId, action: action }),
      });

      if (response.ok) {
        setTracking((prevTracking) => {
          if (action === 'add') {
            return [...prevTracking, billId];
          } else {
            return prevTracking.filter((id) => id !== billId);
          }
        });
      } else {
        console.error('Error saving user bill:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving user bill:', error);
    }
  };

  const renderPageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push("...");

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 2) pages.push("...");
      pages.push(totalPages);
    }

    return pages.map((page, index) =>
        typeof page === "number" ? (
            <Button
                key={index}
                variant={currentPage === page ? "contained" : "outlined"}
                size="small"
                onClick={() => setCurrentPage(page)}
                sx={{
                  color: 'black', 
                  fontSize: 11, fontFamily: 'Lato', fontWeight: '500',
                  margin: "0 4px",
                  minWidth: 30,
                  ...(currentPage === page && { backgroundColor: "primary.main", color: "white" }),
                }}
            >
              {page}
            </Button>
        ) : (
            <Typography key={index} sx={{ margin: "0 8px", fontSize: "14px" }}>
              ...
            </Typography>
        )
    );
  };

  const renderFilterDialog = () => (
    <Dialog
      open={isFilterDialogOpen}
      onClose={handleFilterClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Filter</DialogTitle>
      <DialogContent sx={{paddingTop: '8px !important', marginTop: '8px'}}>
        {renderSelectYears()}
        <br/><br/>
        {renderSelectControl('Select a Tag', selectedTag, handleSelectChange, tags, setSelectedTag)}
        <br/><br/>
        {renderSelectControl('Select an Urgency Level', selectedUrgency, handleUrgencyChange, urgencies, setSelectedUrgency)}
        <br/><br/>
        {renderSelectControl('Select a Level Of Relation', selectedRelation, handleRelationChange, relations, setSelectedRelation)}
        <br/><br/>
        {renderSelectControl('Select a Stance', selectedStance, handleStanceChange, stances, setSelectedStance)}
        <br/><br/>
        {renderSelectControl('Select a Stance Strength', selectedStanceStrength, handleStanceStrengthChange, stanceStrengths, setSelectedStanceStrength)}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleFilterClose}>Cancel</Button>
        <Button onClick={handleFilterSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );

  const renderSelectYears = () => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            openTo="year"
            views={['year']}
            label="Select a Year"
            value={selectedYearIntroduced}
            onChange={(newValue) => {
              setSelectedYearIntroduced(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            maxDate={dayjs()}
        />
      </LocalizationProvider>
  )
  
  const renderSelectControl = (label, value, onChange, options, clearValue) => (
    <FormControl fullWidth>
      <InputLabel id={`${label.toLowerCase().replace(/\s+/g, '-')}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label.toLowerCase().replace(/\s+/g, '-')}-label`}
        value={value}
        label={label}
        onChange={onChange}
        renderValue={(selected) =>
          selected && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <span>{selected}</span>
              <Button
                onMouseDown={(e) => {
                  e.stopPropagation();
                  clearValue('');
                }}
                sx={{ minWidth: '24px', height: '24px', padding: 0, marginLeft: '8px' }}
              >
                ✕
              </Button>
            </Box>
          )
        }
      >
        {options.map((option) => (
          <MenuItem key={option.id || option} value={option.tag || option}>
            {option.tag || option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Layout searchQuery={searchQuery}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ paddingBottom: 4 }}>
          <Box display="flex"  alignItems="center" mb={2}>
            <Button variant="outlined" color="primary" onClick={handleFilterOpen}>
              Filter
            </Button>
            &nbsp; &nbsp;

            <Button variant="contained" color="primary" onClick={exportCsv}>
              Export CSV
            </Button>
          </Box>
          
          {renderFilterDialog()}

          {data.length === 0 ? (
            <p>No New Notifications</p>
          ) : (
            <>
              <h2>We found the following bills based on your tags:</h2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'created_at'}
                            direction={stripMinus(orderBy) === 'created_at' ? order : 'asc'}
                            onClick={() => handleRequestSort('created_at')}
                        >
                          Date Found
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'year_introduced'}
                            direction={stripMinus(orderBy) === 'year_introduced' ? order : 'asc'}
                            onClick={() => handleRequestSort('year_introduced')}
                        >
                          Year Introduced
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__title'}
                            direction={stripMinus(orderBy) === 'bill__title' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__title')}
                        >
                          Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__state'}
                            direction={stripMinus(orderBy) === 'bill__state' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__state')}
                        >
                          State
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__bill_number'}
                            direction={stripMinus(orderBy) === 'bill__bill_number' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__bill_number')}
                        >Bill#</TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel style={tableHeaderCellStyle} 
                            active={stripMinus(orderBy) === 'bill__pending_committee'}
                            direction={stripMinus(orderBy) === 'bill__pending_committee' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__pending_committee')}
                        >
                          Pending Committee
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Tags</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell style={tableHeaderCellStyle}>
                            <IconButton
                                size="small"
                                onClick={() => toggleDetails(item)}
                                aria-label={expandedBills.includes(item.bill.id) ? 'Collapse' : 'Expand'}
                            >
                              {expandedBills.includes(item.bill.id) ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>{item.created_at ? new Date(item.created_at).toLocaleDateString() : ''}</TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            {item.bill.year_introduced ?? ''}
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            <Box display="flex" alignItems="center">
                              <Typography onClick={() => handleBookmarkClick(item.bill.id, tracking.includes(item.bill.id) ? 'remove' : 'add')}>
                                {tracking.includes(item.bill.id) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                              </Typography>
                              <a href={`/bill/${item.bill.id}?tab=1`} target="_blank" rel="noopener noreferrer">
                                {item.bill.title}
                              </a>
                            </Box>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>{item.bill.state}</TableCell>
                          <TableCell style={tableHeaderCellStyle}>{item.bill.bill_number}</TableCell>
                          <TableCell style={tableHeaderCellStyle}>{item.bill.pending_committee}</TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            {item.bill.all_tags && item.bill.all_tags.length > 0
                                ? item.bill.all_tags.join(', ')
                                : 'No tags available'}
                          </TableCell>
                        </TableRow>
                        {expandedBills.includes(item.bill.id) && (
                          <TableRow>
                            <TableCell style={tableHeaderCellStyle} colSpan={6}>
                              <NestedTable
                                  details={cachedBillDetails[item.bill.id] || []}
                                  item={item}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{ maxWidth: 400, overflow: "hidden", whiteSpace: "nowrap" }}
              >
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    sx={{ marginRight: 1, minWidth: 50 }}
                >
                  Previous
                </Button>

                {renderPageNumbers()}

                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    sx={{ marginLeft: 1, minWidth: 50 }}
                >
                  Next
                </Button>
              </Box>

            </>
          )}
        </Box>
      )}
    </Layout>
  );
}

export default NotificationPage;