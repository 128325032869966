import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Box,
    Button,
    Typography,
} from '@mui/material';

const CustomSelect = ({ label, options, value, onChange, isMulti = false }) => {
    const handleRemove = (item) => {
        if (isMulti) {
            onChange(value.filter((selected) => selected !== item));
        } else {
            onChange('');
        }
    };

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
                labelId="text-select-label"
                multiple={isMulti}
                value={value}
                label={label}
                onChange={(e) => onChange(isMulti ? e.target.value : e.target.value)}
                renderValue={(selected) =>
                    isMulti ? (
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            {selected.map((item) => (
                                <Box
                                    key={item}
                                    display="flex"
                                    alignItems="center"
                                    bgcolor="lightgray"
                                    borderRadius={2}
                                    px={1}
                                    py={0.5}
                                >
                                    <Typography variant="body2">{item}</Typography>
                                    <Button
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            handleRemove(item);
                                        }}
                                        sx={{
                                            minWidth: '24px',
                                            height: '24px',
                                            padding: 0,
                                            marginLeft: '4px',
                                        }}
                                    >
                                        ✕
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <span>{selected}</span>
                            <Button
                                size="small"
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleRemove(selected);
                                }}
                                sx={{
                                    minWidth: '24px',
                                    height: '24px',
                                    padding: 0,
                                    marginLeft: '8px',
                                }}
                            >
                                ✕
                            </Button>
                        </Box>
                    )
                }
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                        {isMulti && <Checkbox checked={value.includes(option.name)} />}
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;
