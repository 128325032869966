export const getEvidence = async (attribution_id, candidate_name, setDialogLoading, setCurrentCandidate, setIsEvidenceDialogOpen, setEvidence) => {
    setDialogLoading(true);
    setCurrentCandidate(candidate_name);
    setIsEvidenceDialogOpen(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/attribution/${attribution_id}/evidence`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            }
        });
        const data = await response.json();
        setEvidence(data);
    } catch (error) {
        console.error('Error fetching candidate evidence:', error);
    } finally {
        setDialogLoading(false);
    }
};

export const fetchTags = async (url, setTags, headers = null) => {
    try {
        const response = headers
            ? await fetch(url, { headers })
            : await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setTags(Array.isArray(result) ? result.map(tag => ({ ...tag, name: tag.tag })) : [{ ...result, name: result.tag }]);
    } catch (error) {
        console.error('Error fetching tags:', error);
    }
};

export const fetchStatements = async (userId) => {
    if (!userId) {
        throw new Error("User ID is required to fetch statements.");
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag-statement/?user_id=${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Failed to fetch statements: ${errorMessage}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching statements:', error);
        throw error;
    }
};



