import React from 'react';
import { TableContainer, Table, TableCell, TableHead, TableBody, TableRow, TableSortLabel, Paper, Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { tableHeaderCellStyle } from "../Styles";

const CandidateTable = ({ data, tags, sortConfig, requestSort, handleRowClick, openRow, getEvidence, handleCandidateClick, isPublic = false }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={tableHeaderCellStyle}>
                            <TableSortLabel
                                active={sortConfig.key === 'position_name'}
                                direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                onClick={() => requestSort('position_name')}
                            >
                                Chamber
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle}>
                            <TableSortLabel
                                active={sortConfig.key === 'district'}
                                direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                onClick={() => requestSort('district')}
                            >
                                District
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle}>
                            <TableSortLabel
                                active={sortConfig.key === 'candidate_name'}
                                direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                onClick={() => requestSort('candidate_name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle}>
                            <TableSortLabel
                                active={sortConfig.key === 'party_name'}
                                direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                onClick={() => requestSort('party_name')}
                            >
                                Party
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={tableHeaderCellStyle}>
                            <TableSortLabel
                                active={sortConfig.key === 'total_score'}
                                direction={sortConfig.key === 'total_score' ? sortConfig.direction : 'asc'}
                                onClick={() => requestSort('total_score')}
                            >
                                Agreement
                            </TableSortLabel>
                        </TableCell>
                        {!isPublic && (
                            <TableCell style={tableHeaderCellStyle}>
                                <TableSortLabel
                                    active={sortConfig.key === 'activity_level'}
                                    direction={sortConfig.key === 'activity_level' ? sortConfig.direction : 'asc'}
                                    onClick={() => requestSort('activity_level')}
                                >
                                    Activity Level
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <React.Fragment key={item.id}>
                            <TableRow>
                                <TableCell>{item.position_name}</TableCell>
                                <TableCell>
                                    {item.position_name === "U.S. Senate"
                                        ? item.state
                                        : item.position_name === "U.S. House"
                                            ? item.district && item.district !== 0
                                                ? `${item.state}-${item.district}`
                                                : item.state
                                            : item.district}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" onClick={(e) => handleCandidateClick(e, isPublic, item.id)} style={{ cursor: 'pointer' }}>
                                        {item.photo_url ? (
                                            <img src={item.photo_url} alt={item.candidate_name} style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '8px' }} />
                                        ) : (
                                            <Box sx={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'gray', marginRight: '8px' }} />
                                        )}
                                        <Typography style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {item.candidate_name}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>{item.party_name}</TableCell>
                                <TableCell>
                                    {item.total_score}
                                    {!isPublic && item.total_score !== 'N/A' && (
                                        <IconButton onClick={() => handleRowClick(item.id)}>
                                            {openRow === item.id ? <MinusIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                                        </IconButton>
                                    )}
                                </TableCell>
                                {!isPublic && <TableCell>{item.activity_level}</TableCell>}
                            </TableRow>
                            {openRow === item.id && (
                                <TableRow>
                                    <TableCell colSpan={isPublic ? 5 : 6}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Topic</TableCell>
                                                    <TableCell>Statement</TableCell>
                                                    <TableCell>Score</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tags.map((tag) => (
                                                    <React.Fragment key={tag.id}>
                                                        {tag.statements.map((statement) => (
                                                            <TableRow key={statement.id}>
                                                                <TableCell>{tag.tag}</TableCell>
                                                                <TableCell>{statement.statement}</TableCell>
                                                                <TableCell>
                                                                    {item.attributions.filter(attr => attr.user_tag_statement_id === statement.id).map(attr => (
                                                                        <React.Fragment key={attr.id}>
                                                                            <a href="#" onClick={() => getEvidence(attr.id, item.candidate_name)}>{attr.score}</a>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CandidateTable;