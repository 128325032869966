export const buildQueryString = (params) => {
    return Object.entries(params)
        .filter(([_, value]) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value !== undefined && value !== null && value !== '';
        })
        .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
};

export function debounce(func, delay) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
}
