import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, CircularProgress, DialogActions, DialogContent, Dialog, DialogTitle } from '@mui/material';

import useScorecard from './hooks/useScorecard.js';
import FilterDialog from "./components/FilterDialog";
import EvidenceDialog from "./components/EvidenceDialog";
import BioDialog from "./components/BioDialog";
import CandidateTable from "./components/CandidateTable";
import CandidatePage from "./CandidatePage";
import CandidatePublicDialog from "./components/CandidatePublicDialog";

function ScoreCardPublicPage() {
    const { username } = useParams();
    const navigate = useNavigate();
    const encodedUsername = encodeURIComponent(username);

    const [isCandidateDialogOpen, setIsCandidateDialogOpen] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedCandidteId, setSelectedCandidateId] = useState(null);

    useEffect(() => {
        if (username !== encodedUsername) {
            navigate(`/scorecard-public/${encodedUsername}`, { replace: true });
        }
    }, [username, navigate, encodedUsername]);

    const {
        loading, tags, selectedAttributes, selectedParties, selectedChambers,
        isFilterDialogOpen, sortedData, sortConfig, openRow,
        bioDialogOpen, isEvidenceDialogOpen, bioText, chambers, currentCandidate,
        setIsEvidenceDialogOpen, dialogLoading, evidence, parties, attributes, userId,
        loginDialogOpen, setLoginDialogOpen, selectedCandidateId, handleBioDialogClose, handleFilterDialogOpen,
        handleFilterDialogClose, handleSelectChangeChamber, handleSelectChangeParty, handleSelectChangeAttribute,
        handleFilterSave, requestSort, handleRowClick, getEvidence, handleCandidateClick
    } = useScorecard({ isPublic: true, username: encodedUsername, requiresAuth: false });

    const handleCandidateDialogOpen = (event, isPublic, candidateId) => {
        setSelectedCandidateId(candidateId);
        setIsCandidateDialogOpen(true);
    };

    const handleCandidateDialogClose = () => {
        setIsCandidateDialogOpen(false);
        setSelectedCandidateId(null);
    };

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Box flexGrow={1}>
                <Dialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)} maxWidth="md" fullWidth>
                    <DialogTitle>Candidate Details</DialogTitle>
                    <DialogContent>
                        {selectedCandidateId && <CandidatePage candidateId={selectedCandidateId} isPublic={true} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setLoginDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>

                <FilterDialog
                    open={isFilterDialogOpen}
                    onClose={handleFilterDialogClose}
                    chambers={chambers}
                    selectedChambers={selectedChambers}
                    handleSelectChangeChamber={handleSelectChangeChamber}
                    parties={parties}
                    selectedParties={selectedParties}
                    handleSelectChangeParty={handleSelectChangeParty}
                    attributes={attributes}
                    selectedAttributes={selectedAttributes}
                    handleSelectChangeAttribute={handleSelectChangeAttribute}
                    handleFilterSave={handleFilterSave}
                    isPublic={true}
                />
                <EvidenceDialog
                    open={isEvidenceDialogOpen}
                    onClose={() => setIsEvidenceDialogOpen(false)}
                    evidence={evidence}
                    dialogLoading={dialogLoading}
                    currentCandidate={currentCandidate}
                />
                <BioDialog
                    open={bioDialogOpen}
                    onClose={handleBioDialogClose}
                    bioText={bioText}
                />

                <CandidatePublicDialog
                    open={isCandidateDialogOpen}
                    onClose={handleCandidateDialogClose}
                    candidateId={selectedCandidteId}
                    tags={tags}
                />

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2} ml={4}>
                    <Button variant="outlined" color="primary" onClick={handleFilterDialogOpen}>
                        Filter
                    </Button>
                </Box>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : sortedData.length > 0 ? (
                    <Box sx={{ marginTop: 4, marginRight: 4, marginLeft: 4 }}>  
                        <Typography variant="h4" sx={{ display: 'inline', fontWeight: 'bold' }}>
                            Topic(s):{' '}
                        </Typography>
                        {tags.map((tag, index) => (
                            <Typography key={tag.id} variant="h5" sx={{ display: 'inline',}}>{index > 0 && ', '}{tag.tag}</Typography>
                        ))}

                        <br /><br />
                        <CandidateTable
                            data={sortedData}
                            tags={tags}
                            sortConfig={sortConfig}
                            requestSort={requestSort}
                            handleRowClick={handleRowClick}
                            openRow={openRow}
                            getEvidence={getEvidence}
                            isPublic={true}
                            handleCandidateClick={handleCandidateDialogOpen}
                        />
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <Typography variant="h6">
                            No Results Found
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box
                component="footer"
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    textAlign: 'center',
                    py: 2,
                    backgroundColor: '#fff',
                    borderTop: '1px solid #ddd',
                    boxShadow: '0 -2px 4px rgba(0,0,0,0.1)'
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h6" sx={{ mr: 1, fontWeight: 'bold' }}>
                        Built by
                    </Typography>
                    <Link to="https://www.civiclyenvolved.com/scorecards" target="_blank" rel="noopener noreferrer">
                        <img src="/images/logo.svg" alt="Civicly Envolved Logo" style={{ height: '60px' }} />
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}

export default ScoreCardPublicPage;