import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';

function CandidatePublicDialog({ open, onClose, candidateId, tags }) {
    const [candidate, setCandidate] = useState(null);
    const [bills, setBills] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showFullBio, setShowFullBio] = useState(false);

    useEffect(() => {
        const fetchCandidate = async () => {
            if (!candidateId) return;

            setLoading(true);

            try {
                const tagsQuery = tags.map(tag => tag.id).join(',');
                const candidateResponse = await fetch(`${process.env.REACT_APP_API_URL}/candidate-public/${candidateId}?tags=${tagsQuery}`, {
                    headers: {
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (candidateResponse.ok) {
                    const data = await candidateResponse.json();
                    setCandidate(data['candidate']);
                    setBills(data['bills']);
                } else {
                    console.error('Failed to fetch candidate data');
                }
            } catch (error) {
                console.error('Error fetching candidate data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCandidate();
    }, [candidateId, tags]);

    const toggleBio = () => {
        setShowFullBio(!showFullBio);
    };

    if (!candidateId) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle style={{ backgroundColor: '#b3b3b3', fontSize: '20px'}}><b>Candidate Details</b></DialogTitle>
            <DialogContent style={{ backgroundColor: '#b3b3b3'}}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    candidate && (
                        <>
                        <Box sx={{ backgroundColor: '#fff', padding: '20px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                            <Box display="flex" alignItems="flex-start" mb={2}>
                                {candidate.photo_url ? (
                                    <img src={candidate.photo_url} alt={candidate.candidate_name} style={{ width: '80px', height: '80px', borderRadius: '50%', marginRight: '16px' }} />
                                ) : (
                                    <Box sx={{ width: '80px', height: '80px', borderRadius: '50%', backgroundColor: '#d9d9d9', marginRight: '16px' }} />
                                )}
                                <Typography sx={{ color: '#1e1e1e', textAlign: 'left', verticalAlign: 'top', fontSize: '16px', fontFamily: 'Inter', borderStyle: 'hidden', outline: 'none' }}>
                                    {candidate.candidate_name}
                                    <br />
                                    {candidate.state} / {candidate.district} | {candidate.position_name} | {candidate.party_name}
                                    <br />
                                    {candidate.urls && candidate.urls.map((url) => (
                                        <a href={url.type === 'email' ? `mailto:${url.url}` : url.url} target="_blank" rel="noreferrer"><img width="25" src={`/images/${url.type}.png`} style={{paddingRight: '5px'}} alt={url.type} /></a>
                                    ))}
                                    
                                    {candidate.addresses && candidate.addresses.map((address, index) => (
                                        <Box key={index} sx={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
                                            <Box display="flex" flexWrap="wrap">
                                                {address.phone_numbers && address.phone_numbers.map((phone, phoneIndex) => (
                                                    <React.Fragment key={phoneIndex}>
                                                        {phone.primary_phone && (
                                                            <Box sx={{ marginRight: '20px',padding: '5px', backgroundColor: '#D3D3D3', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                                                <span><b>Phone:</b> {phone.primary_phone}</span>
                                                            </Box>
                                                        )}
                                                        {phone.toll_free && (
                                                            <Box sx={{ marginRight: '20px',padding: '5px', backgroundColor: '#D3D3D3', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                                                <span><b>Toll-Free:</b> {phone.toll_free}</span>
                                                            </Box>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}

                                    <Box sx={{ marginTop: '10px',fontSize: '14px', fontFamily: 'Inter' }}>
                                        {showFullBio ? candidate.bio : `${candidate.bio.substring(0, 50)}...`}
                                        <Button onClick={toggleBio} sx={{ textTransform: 'none', padding: 0, marginLeft: 1 }}>
                                            {showFullBio ? 'Show Less' : 'Show More'}
                                        </Button>
                                    </Box>
                                </Typography>

                                
                                <Box sx={{ width: '80px', height: '80px', borderRadius: '50%', backgroundColor: '#d9d9d9', marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}>
                                    <Typography sx={{ color: '#1e1e1e', fontSize: '20px', fontFamily: 'Inter' }}>
                                        {candidate.total_score}
                                    </Typography>
                                </Box>
                            </Box>
                            
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#b3b3b3', height: '32px', borderRadius: '8px' }}>
                                        <th style={{ paddingLeft: '12px', color: '#1e1e1e', textAlign: 'left', fontSize: '14px', fontFamily: 'Inter' }}>Legislation</th>
                                        <th style={{ color: '#1e1e1e', fontSize: '14px', fontFamily: 'Inter' }}>Their Vote</th>
                                        <th style={{ color: '#1e1e1e', fontSize: '14px', fontFamily: 'Inter' }}>Our Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bills && bills.map((bill) => (
                                        <React.Fragment key={bill.id}>
                                            <tr>
                                                <td style={{ padding: '20px', color: '#1e1e1e', fontSize: '14px', fontFamily: 'Inter' }}>
                                                    <Box style={{ borderRight: '5px solid #D3D3D3'}}>
                                                        <b>Bill #</b> {bill.bill_number} ({bill.year_introduced})
                                                        <Box sx={{ display: 'inline-block', backgroundColor: '#1e1e1e', color: '#fff', borderRadius: '4px', padding: '2px 6px', marginLeft: '8px', fontSize: '12px' }}>
                                                            {bill.status}
                                                        </Box>
                                                        <br />
                                                        {bill.title} <a href={bill.url} target="_blank" rel="noreferrer">Link</a>
                                                    </Box>
                                                </td>
                                                <td style={{ textAlign: 'center', color: '#1e1e1e', fontSize: '24px', fontFamily: 'Inter' }}>
                                                    {bill.reason === 'Voted Yea' || bill.reason === 'Sponsored bill' ? '👍' : '👎'}
                                                </td>
                                                <td style={{ textAlign: 'center', color: '#1e1e1e', fontSize: '24px', fontFamily: 'Inter' }}>
                                                    {bill.stance === 'for' ? '👍' : bill.stance === 'against' ? '👎' : 'N/A'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                        </>
                    )
                )}
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#b3b3b3'}}>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CandidatePublicDialog;