import React, { useState } from 'react';
import Layout from './Layout';
import { Box, Button, Typography, CircularProgress, TextField, MenuItem, Alert } from '@mui/material';
import CandidateTable from './components/CandidateTable';
import useScorecard from './hooks/useScorecard.js';
import EvidenceDialog from './components/EvidenceDialog';
import BioDialog from './components/BioDialog';
import FilterDialog from './components/FilterDialog';
import ScorecardManageDialog from './components/ScorecardManageDialog';

function ScorecardPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);

    const {
        loading, tags, selectedAttributes, selectedParties, selectedChambers,
        isFilterDialogOpen, sortedData, sortConfig, openRow,
        bioDialogOpen, isEvidenceDialogOpen, bioText, chambers, currentCandidate,
        setIsEvidenceDialogOpen, dialogLoading, evidence, parties, attributes, userId,
        handleBioDialogClose, handleFilterDialogOpen, handleFilterDialogClose, handleSelectChangeChamber, handleSelectChangeParty,
        handleSelectChangeAttribute, handleFilterSave, requestSort, handleRowClick, getEvidence, handleCandidateClick,
        handleManageDialogOpen, handleManageDialogClose, isManageDialogOpen
    } = useScorecard({ username: null, requiresAuth: true });
    
    return (
        <Layout searchQuery={searchQuery}>
            <FilterDialog open={isFilterDialogOpen}
                          onClose={handleFilterDialogClose}
                          chambers={chambers}
                          selectedChambers={selectedChambers}
                          handleSelectChangeChamber={handleSelectChangeChamber}
                          parties={parties}
                          selectedParties={selectedParties}
                          handleSelectChangeParty={handleSelectChangeParty}
                          attributes={attributes}
                          selectedAttributes={selectedAttributes}
                          handleSelectChangeAttribute={handleSelectChangeAttribute}
                          handleFilterSave={handleFilterSave}
            />

            <EvidenceDialog open={isEvidenceDialogOpen}
                            onClose={() => setIsEvidenceDialogOpen(false)}
                            evidence={evidence}
                            dialogLoading={dialogLoading}
                            currentCandidate={currentCandidate}
            />

            <BioDialog
                open={bioDialogOpen}
                onClose={handleBioDialogClose}
                bioText={bioText}
            />

            <ScorecardManageDialog
                open={isManageDialogOpen}
                onClose={handleManageDialogClose}
                userId={userId}
            />

            {/* Buttons */}
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" onClick={handleFilterDialogOpen}>
                    Filter
                </Button>

                <Button variant="outlined" color="secondary" onClick={handleManageDialogOpen}>
                    Manage
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : sortedData.length > 0 ? (
                <Box sx={{ marginTop: 4, marginRight: 4 }}>
                    <Typography variant="h4" sx={{ display: 'inline', fontWeight: 'bold' }}>
                        Topic(s):{' '}
                    </Typography>
                    {tags.map((tag, index) => (
                        <Typography key={tag.id} variant="h5" sx={{ display: 'inline', ml: index > 0 ? 1 : 0 }}>
                            {tag.tag}
                        </Typography>
                    ))}
                    <br /><br />
                    <CandidateTable
                        data={sortedData}
                        tags={tags}
                        sortConfig={sortConfig}
                        requestSort={requestSort}
                        handleRowClick={handleRowClick}
                        openRow={openRow}
                        getEvidence={getEvidence}
                        isAuthenticated={!!userId}
                        handleCandidateClick={handleCandidateClick}
                    />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography variant="h6">
                        No Results Found
                    </Typography>
                </Box>
            )}
        </Layout>
    );
}

export default ScorecardPage;
