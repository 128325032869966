import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function OverviewTab({ id, vote, bill }) {
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [activityHistory, setActivityHistory] = useState([]);
  const [isActivityHistoryFetched, setIsActivityHistoryFetched] = useState(false);
  const userId = localStorage.getItem('user_id');
  
  const steps = ['Prefiled', 'Introduced', 'Engrossed', 'Enrolled', 'Passed', 'Vetoed', 'Failed'];

  const activeStep = steps.findIndex(step => step === bill?.status);
  const [showAllSponsors, setShowAllSponsors] = useState(false);

  const icons = {};
  icons['Strongly Disagree'] = {'emoji': '🤯', 'color': '#F99E17'};
  icons['Disagree'] =  {'emoji': '😕', 'color': '#FFE500'};
  icons['Neutral'] =  {'emoji': '😐', 'color': '#949494'};
  icons['Agree'] =  {'emoji': '🙂', 'color': '#00E709'};
  icons['Strongly Agree'] =  {'emoji': '🥳', 'color': '#00A006'};

  useEffect(() => {
    if (vote) {
      setSelectedKey(vote);
      setSelectedColor(icons[vote]?.color || null);
    }
  }, [vote]);

  useEffect(() => {
    if (!isActivityHistoryFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-history/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setActivityHistory(data);
          setIsActivityHistoryFetched(true);
        })
        .catch(error => console.error('Error fetching activity history:', error));
    }
  }, [id, isActivityHistoryFetched]);

  const handleClick = async (key, color) => {
    setSelectedKey(key);
    setSelectedColor(color);
    
    const voteData = {
      user_id: userId,
      source_id: id,
      source: 'bill',
      vote: key
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/votes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify(voteData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Vote submitted successfully:', responseData);
    } catch (error) {
      console.error('Error submitting vote:', error);
    }
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <h3>Our Position</h3>
      
      <Box sx={{ display: 'flex', gap: 2 }}>
        {Object.entries(icons).map(([key, { emoji, color }], index) => (
          <Tooltip key={index} title={key} arrow>
            <Button
              key={index}
              onClick={() => handleClick(key, color)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 60,
                height: 60,
                fontSize: 30,
                border: '1px solid #E0E0E0',
                borderRadius: '50%',
                backgroundColor: selectedKey === key ? color : '#f0f0f0',
                '&:hover': {
                  backgroundColor: color,
                },
              }}
            >
              {emoji}
            </Button>
          </Tooltip>
        ))}
      </Box>

      <h3>Process Stage</h3>
      <Box sx={{ width: '50%',  marginBottom: '16px' }}>
        {bill?.status ? <p>{bill.status}</p> : <p>Status not available</p>}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {bill?.controversy_level && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
            <Typography sx={{ marginRight: 1 }}>
              <b>Controversy:</b>
            </Typography>
            {Array.from({ length: 5 }).map((_, index) => (
                <img
                    key={index}
                    src={
                      index < Math.floor(bill.controversy_level / 2)
                          ? '/images/fire-on.svg'
                          : '/images/fire-off.svg'
                    }
                    alt="Fire"
                    style={{ width: 20, height: 20, marginRight: 4 }}
                />
            ))}
          </Box>
      )}

      {bill?.sponsors && bill.sponsors.length > 0 && (
          <Typography sx={{ marginTop: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <b>Sponsor(s):</b>&nbsp;
            {showAllSponsors
                ? bill.sponsors.map((s) => s.name).join(', ')
                : bill.sponsors.slice(0, 2).map((s) => s.name).join(', ')}

            {bill.sponsors.length > 2 && (
                <Button
                    variant="text"
                    size="small"
                    startIcon={showAllSponsors ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setShowAllSponsors(!showAllSponsors)}
                    sx={{ marginLeft: 1 }}
                >
                </Button>
            )}
          </Typography>
      )}

      {bill?.category && bill.category.length > 0 && (
          <Typography sx={{ marginTop: 1 }}>
            <b>Tag(s):</b> {bill.category}
          </Typography>
      )}

      <h3>Activity History:</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: '1px solid #E0E0E0' }} aria-label="activity history table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E0E0E0' }}>
              <TableCell sx={{ border: '1px solid #E0E0E0' }}>Date</TableCell>
              <TableCell sx={{ border: '1px solid #E0E0E0' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityHistory.map((activity, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #E0E0E0' }}>{activity.date}</TableCell>
                <TableCell sx={{ border: '1px solid #E0E0E0' }}>{activity.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default OverviewTab;