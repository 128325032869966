import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, List, ListItem, ListItemText, Alert, Box, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchStatements } from '../api/apiService';

const ScorecardManageDialog = ({ open, onClose, userId }) => {
    const [overrides, setOverrides] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [billId, setBillId] = useState('');
    const [option, setOption] = useState('include');
    const [alignmentOption, setAlignmentOption] = useState('for');
    const [isSaving, setIsSaving] = useState(false);
    const [statements, setStatements] = useState([]);
    const [selectedStatement, setSelectedStatement] = useState('');

    const handleSave = async () => {
        if (!billId) {
            setErrorMessage("Bill ID cannot be empty.");
            setTimeout(() => setErrorMessage(null), 3000);
            return;
        }

        if (!selectedStatement) {
            setErrorMessage("Statement cannot be empty.");
            setTimeout(() => setErrorMessage(null), 3000);
            return;
        }

        setIsSaving(true);

        try {           
            const response = await fetch(`${process.env.REACT_APP_API_URL}/bill-override/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                },
                body: JSON.stringify({
                    bill: billId,
                    option: option,
                    user_id: userId,
                    statement_id: selectedStatement,
                    stance: alignmentOption,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to save bill override.");
            }

            setSuccessMessage(`Successfully saved Bill ID: ${billId} as ${option.toUpperCase()}`);
            setBillId('');
            setOption('include');

            fetchBillOverrides();

            // Hide success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        } catch (error) {
            console.error('Error saving bill override:', error);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(null), 3000);
        } finally {
            setIsSaving(false);
        }
    };
    
    useEffect(() => {
        if (open) {
            fetchBillOverrides();
            fetchStatements(userId);
        }
    }, [open]);


    useEffect(() => {
        const getStatements = async () => {
            try {
                const data = await fetchStatements(userId);
                setStatements(data);
            } catch (error) {
                setErrorMessage(error.message || 'Failed to load statements.');
                setTimeout(() => setErrorMessage(null), 3000);
            }
        };

        getStatements();
    }, [userId]);

    const fetchBillOverrides = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/bill-override/?user_id=${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch bill overrides');
            }

            const data = await response.json();
            setOverrides(data);
        } catch (error) {
            console.error('Error fetching bill overrides:', error);
            setErrorMessage('Failed to load bill overrides.');
            setTimeout(() => setErrorMessage(null), 3000);
        }
    };

    const handleDelete = async (billId, statementId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/bill-override/${billId}/?user_id=${userId}&statement_id=${statementId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete bill override');
            }

            // Show success message
            setSuccessMessage(`Bill ID ${billId} deleted successfully.`);
            setTimeout(() => setSuccessMessage(null), 3000);

            // Fetch updated list
            fetchBillOverrides();
        } catch (error) {
            console.error('Error deleting bill override:', error);
            setErrorMessage('Failed to delete bill override.');
            setTimeout(() => setErrorMessage(null), 3000);
        }
    };

    return ( 
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Scorecard Manage</DialogTitle>
            <DialogContent>
                {/* Success & Error Messages */}
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <br />
                {/* Bill ID input & option selection */}
                <Box width="100%">
                    <TextField
                        label="Bill ID"
                        variant="outlined"
                        size="small"
                        value={billId}
                        onChange={(e) => setBillId(e.target.value)}
                        fullWidth
                    />
                    <br /><br />
                    <TextField
                        select
                        label="Option"
                        variant="outlined"
                        size="small"
                        value={option}
                        onChange={(e) => setOption(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="include">Include</MenuItem>
                        <MenuItem value="exclude">Exclude</MenuItem>
                    </TextField>
                    <br /><br />
                    <TextField
                        select
                        label="Statement"
                        variant="outlined"
                        size="small"
                        value={selectedStatement}
                        onChange={(e) => setSelectedStatement(e.target.value)}
                        fullWidth
                    >
                        {statements.map((statement) => (
                            <MenuItem key={statement.id} value={statement.id}>
                                {statement.statement}
                            </MenuItem>
                        ))}
                    </TextField>
                    <br /><br />
                    <TextField
                        select
                        label="Alignment"
                        variant="outlined"
                        size="small"
                        value={alignmentOption}
                        onChange={(e) => setAlignmentOption(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="for">For</MenuItem>
                        <MenuItem value="against">Against</MenuItem>
                    </TextField>
                    <br /><br />
                    <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                    <br /><br />
                    <b>Note: </b>For exclude to take affect, refresh the page. Includes will take a few minutes to process.
                </Box>
                <br />

                {overrides.length === 0 ? (
                    <ListItemText primary="No bill overrides found." />
                ) : (
                    <table style={{ border: '1px solid #ccc', width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Bill ID</th>
                                <th>Option</th>
                                <th>Statement</th>
                                <th>Alignment</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {overrides.map((override) => (
                                <tr key={override.bill}>
                                    <td style={{ textAlign: 'center' }}>{override.bill}</td>
                                    <td style={{ textAlign: 'center' }}>{override.option}</td>
                                    <td style={{ textAlign: 'center' }}>{override.user_tag_statement_statement}</td>
                                    <td style={{ textAlign: 'center' }}>{override.stance}</td>
                                    <td style={{ align: 'center' }}>
                                        <IconButton edge="end" onClick={() => handleDelete(override.bill, override.user_tag_statement)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScorecardManageDialog;