import React from 'react';
import { Box, Tooltip, TableCell } from '@mui/material';

const AttributesCandidate = ({ attribute, index }) => {
    const isDark = index % 2 === 0;
    const backgroundColor = isDark ? '#0B7269' : '#CCFBF1';
    const textColor = isDark ? '#FFFFFF' : '#0B7269';

    return (
        <Tooltip title={attribute.attribute_type_name}>
            <Box
                sx={{
                    padding: '8px 16px',
                    backgroundColor,
                    borderRadius: '6px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: textColor,
                    fontSize: '14px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                    whiteSpace: 'nowrap',
                }}
            >
                {attribute.attribute_type_abbr || attribute.attribute_type_name}
            </Box>
        </Tooltip>
    );
};

const AttributesTableCell = ({ vote }) => {
    return (
        <TableCell
            sx={{
                border: '1px solid #E0E0E0',
                width: 'auto',
                minWidth: 'fit-content',
                maxWidth: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    overflow: 'hidden',
                }}
            >
                {vote.attributes.map((attribute, index) => (
                    <AttributesCandidate key={index} attribute={attribute} index={index} />
                ))}
            </Box>
        </TableCell>
    );
};

export default AttributesTableCell;
