import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildQueryString } from '../utils/utils.js';
import hasFeatureAccess from "../utils/featureAccess";
import { getEvidence, fetchTags } from '../api/apiService';

const useScorecard = ({isPublic = false, username = null}) => {
    const userId = localStorage.getItem('user_id');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isEvidenceDialogOpen, setIsEvidenceDialogOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [evidence, setEvidence] = useState([]);
    const [currentCandidate, setCurrentCandidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);

    // Dialog states
    const [bioDialogOpen, setBioDialogOpen] = useState(false);
    const [bioText, setBioText] = useState('');
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

    // Filters
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [savedAttributes, setSavedAttributes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [parties, setParties] = useState(['Democratic', 'Republican', 'Other']);
    const [chambers, setChambers] = useState(['House', 'Senate']);
    const [selectedParties, setSelectedParties] = useState([]);
    const [selectedChambers, setSelectedChambers] = useState([]);
    const [savedParties, setSavedParties] = useState([]);
    const [savedChambers, setSavedChambers] = useState([]);

    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);

    const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);

    const handleManageDialogOpen = () => {
        setIsManageDialogOpen(true);
    };

    const handleManageDialogClose = () => {
        setIsManageDialogOpen(false);
    };

    const handleCandidateClick = (event, isPublic, candidateId) => {
        if (isPublic) {
            event.preventDefault(); // Prevent default navigation
            setLoginDialogOpen(true); // Open login dialog
            setSelectedCandidateId(candidateId);
        } else {
            navigate(`/candidate/${candidateId}`); // Redirect to candidate page normally
        }
    };

    const queryParams = useMemo(() => ({
        attributes: savedAttributes || [],
        parties: savedParties || [],
        chambers: savedChambers || [],
    }), [savedAttributes, savedParties, savedChambers]);

    useEffect(() => {
        if (!hasFeatureAccess('scorecard') && !username) {
            navigate('/access-denied');
        }
    }, [navigate, userId, username]);

    const handleFilterSave = () => {
        setSavedAttributes(selectedAttributes);
        setSavedParties(selectedParties);
        setSavedChambers(selectedChambers);
        setIsFilterDialogOpen(false);
    };

    const handleFilterDialogOpen = () => {
        savedFilters();
        setIsFilterDialogOpen(true);
    };

    const handleFilterDialogClose = () => {
        savedFilters();
        setIsFilterDialogOpen(false);
    };

    const savedFilters = () => {
        setSelectedAttributes(savedAttributes);
        setSelectedParties(savedParties);
        setSavedChambers(selectedChambers);
    };

    const handleBioClick = (bio) => {
        setBioText(bio);
        setBioDialogOpen(true);
    };

    const handleBioDialogClose = () => {
        setBioDialogOpen(false);
        setBioText('');
    };

    const handleSelectChangeAttribute = (data) => {
        setSelectedAttributes(data);
    };

    const handleSelectChangeParty = (data) => {
        setSelectedParties(data);
    };

    const handleSelectChangeChamber = (data) => {
        setSelectedChambers(data);
    };

    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/candidate-attributes/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setAttributes(result);
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        };

        fetchAttributes();
    }, []);

    useEffect(() => {
        const fetchData = async (queryParams) => {
            setLoading(true);
            try {
                const queryString = buildQueryString(queryParams);
                console.log('username', username);
                const url = username
                    ? `${process.env.REACT_APP_API_URL}/candidates/by-state/public/${username}/?${queryString}` // Public API
                    : `${process.env.REACT_APP_API_URL}/candidates/by-state/${userId}/?${queryString}`; // Private API

                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch candidates URLs');
                }

                const ret = await response.json();
                setData(ret);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData(queryParams);
    }, [savedAttributes, savedParties, savedChambers, queryParams, username, userId]);

    useEffect(() => {
        if (username) {
            fetchTags(`${process.env.REACT_APP_API_URL}/user-tag/by-username/${username}/`, setTags);
        } else if (!userId) {
            setTags([]);
        } else {
            fetchTags(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, setTags, {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            });
        }
    }, [username, userId]);

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];

        if (sortConfig.key === 'candidate_attributes') {
            sortableData.sort((a, b) => {
                const aValue = a.candidate_attributes[0]?.attribute_type_name || '';
                const bValue = b.candidate_attributes[0]?.attribute_type_name || '';
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        else if (sortConfig.key) {
            sortableData.sort((a, b) => {
                const aValue = a[sortConfig.key] === 'N/A' ? -Infinity : a[sortConfig.key];
                const bValue = b[sortConfig.key] === 'N/A' ? -Infinity : b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const [openRow, setOpenRow] = useState(null);

    const handleRowClick = (rowId) => {
        setOpenRow(openRow === rowId ? null : rowId);
    };

    const fetchCandidateEvidence = (attribution_id, candidate_name) => {
        getEvidence(attribution_id, candidate_name, setDialogLoading, setCurrentCandidate, setIsEvidenceDialogOpen, setEvidence);
    };

    return {
        userId,
        searchQuery,
        setSearchQuery,
        data,
        loading,
        bioDialogOpen,
        bioText,
        selectedAttributes,
        selectedParties,
        selectedChambers,
        attributes,
        tags,
        dialogLoading,
        evidence,
        currentCandidate,
        sortedData,
        openRow,
        sortConfig,
        isFilterDialogOpen,
        isEvidenceDialogOpen,
        setBioDialogOpen,
        setIsEvidenceDialogOpen,
        setIsFilterDialogOpen,
        parties,
        chambers,
        setParties,
        setChambers,
        loginDialogOpen,
        setLoginDialogOpen,
        selectedCandidateId,
        isManageDialogOpen,
        handleBioClick,
        handleBioDialogClose,
        handleFilterDialogOpen,
        handleFilterDialogClose,
        handleFilterSave,
        handleSelectChangeAttribute,
        handleSelectChangeParty,
        handleSelectChangeChamber,
        getEvidence: fetchCandidateEvidence,
        requestSort,
        handleRowClick,
        handleCandidateClick,

        handleManageDialogOpen,
        handleManageDialogClose,
    };
};

export default useScorecard;
