import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import BillPage from './BillPage';
import ChatPage from './ChatPage';
import TagPage from './TagPage';
import NotificationPage from './NotificationPage';
import ProfilePage from './ProfilePage';
import ScorecardPate from './ScorecardPage';
import UpcomingPage from './UpcomingPage';
import AccessDeniedPage from './AccessDeniedPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import CustomChatPage from './CustomChatPage';
import FilePage from './FilePage';
import MessagePage from './MessagePage';
import SourcePage from './SourcePage';
import CandidatePage from './CandidatePage';
import NetworkAnalyticsPage from './NetworkAnalyticsPage';
import AdminPage from './AdminPage';
import ScoreCardPublicPage from './ScoreCardPublicPage';

const theme = createTheme();

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false} >
          <CssBaseline />
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/bill/:id" element={<BillPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/custom-chat" element={<CustomChatPage />} />
            <Route path="/message/:id" element={<MessagePage />} />
            <Route path="/files/:id" element={<FilePage />} />
            <Route path="/source/:id" element={<SourcePage />} />
            <Route path="/tag" element={<TagPage />} />
            <Route path="/notification" element={<NotificationPage />} />
            <Route path="/scorecard" element={<ScorecardPate />} />
            <Route path="/scorecard-public/:username" element={<ScoreCardPublicPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/upcoming" element={<UpcomingPage />} />
            <Route path="/access-denied" element={<AccessDeniedPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:uidb64/:token/" element={<ResetPasswordPage />}  />
            <Route path="/candidate/:id" element={<CandidatePage />} />
            <Route path="/network-analytics" element={<NetworkAnalyticsPage />} />
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;