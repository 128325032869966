import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
const queryParams = new URLSearchParams(window.location.search);
const adminParam = queryParams.get('xAxis');
const navigate = useNavigate();

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                },          
                body: JSON.stringify({ 'tree': adminParam })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                const result = await response.json();
                localStorage.setItem('loginData', JSON.stringify(result.user));
                localStorage.setItem('user_id', result.user.id);
                localStorage.setItem('features', JSON.stringify(result.features));
                console.log(result.package);
                localStorage.setItem('package', result.package);
                localStorage.setItem('adminLogin', true);
                navigate('/home'); // Redirect to home page on successful login
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    fetchData();
}, []);

  return (
    <div>
        <h1>Admin Page</h1>
    </div>
  );
};

export default AdminPage;